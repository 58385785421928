import PropTypes from "prop-types";
import React from "react";

const ExternalLink = (props) => {
    return (
        <a className={"text-ap-action-bg hover:text-ap-action-hover " + (props.className ?? "")} href={props.to} target={props.target}>
            {props.children}
        </a>
    )
}

ExternalLink.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    target: PropTypes.string
};

export default ExternalLink;