import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import DivWithText from "../components/DivWithText";
import UnorderedList from "../components/UnorderedList";
import ExternalLink from "../components/ExternalLink";

function ServicesPage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Medical Records`]}
                title="Medical Records"
                description="How to obtain medical records from Agile Psychiatry"
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Medical Records</h2>
                        <DivWithText className="border-solid border-ap-dark-bg border-2 rounded-md p-4 mt-4 mb-4 bg-ap-dark-bg text-white">
                            <h4 className="text-2xl mb-2">Overview</h4>
                            <UnorderedList className="ml-6">
                                {/*<li>Accepting new patients from 18-60 years old</li>*/}
                                <li><span className="italic">Last updated 2/1/23</span></li>
                                <li>Former patients should mail or fax in the form below</li>
                                <li>These processes may change in the future</li>
                                <li>Check this page in the future for the most up-to-date information</li>
                            </UnorderedList>
                        </DivWithText>
                        <TextParagraph className="mb-8">
                            <span className="font-bold">If you were previously a patient at Agile Psychiatry</span> and would
                            like to request your medical records, please send <ExternalLink to="https://www.texasattorneygeneral.gov/sites/default/files/files/divisions/consumer-protection/hb300-Authorization-Disclose-Health-Info.pdf">
                            this form</ExternalLink> to Dr. Nahas via fax or mail (see below).
                        </TextParagraph>
                        <TextParagraph className="mb-8">
                            In accordance with Texas law, the fee for duplicating and sending your records is $25 for the
                            first 20 pages and $0.50 per page thereafter for paper copies. For digital copies, the fee
                            for duplicating and sending your records is $25 for 500 or fewer pages and $50 for more than
                            500 pages.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv>
                        <TextParagraph className="mb-8 text-center">
                            Brian Nahas, MD<br/>
                            3616 Far West Blvd Ste 117<br/>
                            Box #303<br/>
                            Austin, TX 78731<br/>
                        </TextParagraph>
                        <TextParagraph className="mb-8 text-center">
                            Fax: 216-208-1379<br/>
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <TextDiv className="h-20"/>
                </SectionRow>
            </Section>
        </LayoutGeneral>
    );
}


export default ServicesPage;
